<template>
  <div
    class="card"
    :class="classes"
  >
    <div class="card__price">
      <basic-product-price
        v-if="localProduct?.prices?.length > 0"
        :product="localProduct"
        :prefix="pricePrefix"
        :suffix="$t('product.currency')"
        price-class="lead-1"
        suffix-class="body-1"
      />
    </div>

    <div
      class="card__name"
      v-html="$t(`content.products.${productId}.name`)"
    />

    <div class="card__description body-2">
      <div
        class="description__text"
        v-html="$t(`content.products.${productId}.description`)"
      />

      <template v-if="$te(`content.products.${productId}.altDescription`)">
        <div class="description__bullets">
          <div
            v-for="(text, index) in $t(`content.products.${productId}.altDescription`)"
            :key="index"
            class="bullet"
            v-html="text"
          />
        </div>
      </template>

      <template v-else>
        <div class="description__bullets">
          <div
            v-for="(text, index) in $t(`content.products.${productId}.bulletPoints`)"
            :key="index"
            class="bullet"
            v-text="text"
          />
        </div>
      </template>

      <div class="description__pricing">
        <basic-product-pricing
          v-if="localProduct?.prices?.length > 0"
          :product="localProduct"
        />
      </div>
    </div>

    <div class="card__message">
      <basic-message
        v-if="productState && productState !== PRODUCT_STATE.EDITABLE"
        :severity="NOTIFICATION.WARNING"
        :closeable="false"
        :content="$t('product.detail.cancellation.message')"
      />

      <basic-message
        v-if="person && !localProduct.prices && $te(`content.products.${localProduct.productId}.unavailable`)"
        :severity="NOTIFICATION.WARNING"
        :closeable="false"
        :content="$t(`content.products.${localProduct.productId}.unavailable`)"
      />

      <template
        v-for="message in localProduct?.messages"
        :key="message.key"
      >
        <basic-message
          :severity="message.type.toLowerCase()"
          :content="$t(`error.${message.key}`)"
        />
      </template>
    </div>

    <div class="card__criteria">
      <product-options
        v-if="categoryId && groupId && person"
        :category-id="categoryId"
        :group-id="groupId"
        :person="person"
        :product="localProduct"
        :source="$props.source"
        @change="selectOption"
      />
    </div>

    <div class="card__action">
      <product-action
        v-if="$props.personId && isProductEditable"
        :person-id="$props.personId"
        :product="localProduct"
        :is-dirty="isDirty"
        @add="add"
        @apply="apply"
        @remove="remove"
        @search="searchMedicalOffice"
      />

      <basic-button
        v-else-if="!$props.personId"
        :label="$t('benefits.widget.productLinkText')"
        @click="$emit('open-form')"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'

import { events$, loading$ } from '@/services'

import basketStore from '@/store/basket'
import useI18n from '@/hooks/useI18n'
import usePersonSpecificProduct from '@/hooks/usePersonSpecificProduct'
import useProduct from '@/hooks/useProduct'
import useProductPersons from '@/hooks/useProductPersons'
import usePerson from '@/hooks/usePerson'
import useProductState from '@/hooks/useProductState'
import useSnackbar from '@/hooks/useSnackbar'
import { ReactivityUtil } from '@/utils/Reactivity'

import { BasicButton, BasicMessage, BasicProductPrice, BasicProductPricing } from '@/components/Basic'

import ProductAction from '@/components/Product/ProductAction'
import ProductOptions from '@/components/Product/Options'

import { NOTIFICATION, PRODUCT_STATE } from '@/config/constants'
import { EVENT_MEDICAL_OFFICE_MODAL_OPEN, EVENT_PRODUCT } from '@/config/events'
import { isProductReadOnlyHaX } from '@/utils/HaX'

// HOOKS
const { t } = useI18n()
const { getPerson } = usePerson()
const { removeProductFromPerson, changeProductOption, updateProductForPerson } = usePersonSpecificProduct()
const { getCategoryIdFromProduct, getGroupIdFromProduct, isUpgrade } = useProduct()
const { addProductToOvpBasket, addProductWithDoctor } = useProductPersons()
const { state } = useProductState()
const { addToast } = useSnackbar()

// INIT
defineEmits(['open-form'])
const props = defineProps({
  isNarrow: {
    type: Boolean,
    default: false,
  },

  personId: {
    type: String,
    default: null,
  },

  product: {
    type: Object,
    required: true,
  },

  source: {
    type: String,
    required: true,
  },
})

// DATA
const localProduct = ref(props.product)
const isDirty = ref(false)

// COMPUTED
const categoryId = computed(() => {
  return getCategoryIdFromProduct(props.product.productId)
})

const classes = computed(() => {
  return {
    'card--wide': !props.isNarrow,
    'product--invalid': !props.product.valid && !!props.product?.messages?.find(m => m.type === 'ERROR'),
    'product--unavailable': !props.product.valid && !!props.product?.messages?.find(m => m.type === 'INFO'),
  }
})

const groupId = computed(() => {
  return getGroupIdFromProduct(props.product.productId)
})

const isProductEditable = computed(() => {
  return productState.value === PRODUCT_STATE.EDITABLE && !isProductReadOnlyHaX(productId.value)
})

const person = computed(() => {
  return getPerson(props.personId)
})

const pricePrefix = computed(() => {
  return props.product.value?.showDiscountGroup && !props.product.value.selected ? t('product.asof') : ''
})

const productId = computed(() => {
  return props.product.productId
})

const productState = computed(() => {
  if (!person.value) return

  const partnerNumber = person.value.partnerNumber
  return state(partnerNumber, productId.value)
})

// METHODS
async function add(__options) {
  if (__options.replace && !__options.forceAdd) {
    events$.emit(EVENT_PRODUCT.REPLACE_OPEN, {
      fromProductId: __options.productId,
      person: getPerson(props.personId),
      toProduct: props.product,
      source: props.source,
    })
  } else {
    try {
      loading$.start()
      const mode = isUpgrade(productId.value) ? 'ADD' : 'REPLACE'

      let basket = updateProductForPerson({ personId: props.personId, product: localProduct.value })
      basket = await addProductToOvpBasket(props.personId, productId.value, { basket, mode })
      basket = await basketStore.updateOvpBasket(basket)

      events$.emit(EVENT_PRODUCT.ADDED, {
        basket,
        categoryId: categoryId.value,
        personId: props.personId,
        productId: props.product.productId,
        source: props.source,
      })

      localProduct.value = props.product

      isDirty.value = false
      loading$.end()
    } catch (error) {
      loading$.failed()

      error.messages.forEach(message => {
        addToast({
          type: NOTIFICATION.ERROR,
          text: message,
        })
      })
    }
  }
}

async function apply() {
  loading$.start()

  try {
    const basket = updateProductForPerson({ personId: props.personId, product: localProduct.value })

    await basketStore.updateOvpBasket(basket)

    isDirty.value = false

    loading$.end()
  } catch (error) {
    console.error(error)
    loading$.failed()
  }
}

async function remove() {
  loading$.start()

  try {
    const __basket = await removeProductFromPerson(props.personId, productId.value)

    events$.emit(EVENT_PRODUCT.REMOVED, {
      basket: __basket,
      categoryId: categoryId.value,
      personId: props.personId,
      productId: productId.value,
      source: props.source,
    })

    localProduct.value = props.product

    isDirty.value = false
    loading$.end()
  } catch (error) {
    loading$.failed()

    error.messages.forEach(message => {
      addToast({
        type: NOTIFICATION.ERROR,
        text: message,
      })
    })
  }
}

function searchMedicalOffice() {
  events$.emit(EVENT_MEDICAL_OFFICE_MODAL_OPEN, {
    source: props.source,
    personId: props.personId,
    product: localProduct.value,
    callback: async data => {
      loading$.start()

      try {
        await addProductWithDoctor({
          categoryId: props.categoryId,
          doctor: data,
          personId: props.personId,
          productId: props.product.productId,
          source: props.source,
        })

        loading$.end()
      } catch {
        loading$.failed()
      }
    },
  })
}

async function selectOption(option) {
  loading$.start()

  try {
    const updatedProductCriteria = await changeProductOption({
      option,
      personId: props.personId,
      product: ReactivityUtil.clone(localProduct.value),
    })

    ReactivityUtil.reAssign(localProduct.value, updatedProductCriteria)
    if (localProduct.value.selected) {
      isDirty.value = true
    }
    loading$.end()
  } catch (error) {
    console.error(error)
    loading$.failed()
  }
}

// WATCHERS
watch(
  () => props.product,
  value => {
    localProduct.value = value
  },
  { deep: true }
)
</script>

<style scoped>
.card {
  display: grid;
  grid-row: 1 / -1;
  grid-template-columns: 1fr;
  grid-template-rows: subgrid;

  padding: var(--fixed-spacing-fix-06);

  border: 1px solid var(--outline-variant);
  border-radius: var(--radius-border-radius-03);

  &--unavailable {
    color: var(--on-surface-low);
  }
}

.card__name {
  font-size: var(--font-size-bold-3xl);
  letter-spacing: var(--letter-spacing-bold-3xl);
  line-height: var(--line-height-bold-3xl);
  font-weight: var(--font-weight-bold);
  margin: 0 0 var(--fixed-spacing-fix-04);
}

.card__description {
  margin: 0 0 var(--fixed-spacing-fix-04);
}

.description__text {
  margin: 0 0 var(--fixed-spacing-fix-02);
}

.description__bullets {
  margin: 0 0 0 var(--fixed-spacing-fix-04);

  .bullet {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      height: 4px;
      width: 4px;
      background-color: black;
      border-radius: 50%;
      top: 10px;
      left: -10px;
    }
  }
}

.description__pricing:deep(.pricing):not(:empty) {
  margin: var(--fixed-spacing-fix-02) 0 0;
}

.card__message:not(:empty) {
  margin: 0 0 var(--fixed-spacing-fix-04);
}

.card__message .message + .message {
  margin: var(--fixed-spacing-fix-04) 0 0;
}

.card__criteria:not(:empty) {
  margin: 0 0 var(--fixed-spacing-fix-05);
}

.card__action:deep(.button) {
  width: 100%;
}
</style>
